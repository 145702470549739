import { FETCH_REQUISITES_SUCCESS } from '../_actions/types';


const initialState = {
    upsells: [],
    cities: [],
    types: [],
    stripe: [],
    facilities: [],
    locales: [],
    currency: null,
  };
  
const coreReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REQUISITES_SUCCESS:
      return {
        ...state,
        upsells: action.payload.upsells,
        cities: action.payload.cities,
        types: action.payload.types,
        stripe: action.payload.stripe,
        facilities: action.payload.facilities,
        currency: action.payload.currency,
        locales: action.payload.locales,
      };
    default:
      return state;
  }
};

export default coreReducer;
