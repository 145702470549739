import React, { FC, useEffect } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { getNameFromLocale } from "../../helper";
import i18n from "../../services/i18n";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  setStripe: (stripe: any) => void;
  setElements: (elements: any) => void;
  setError: (error: string | null) => void;
  error: string | null;
  formRef: React.RefObject<HTMLFormElement>; // Declare a new prop for formRef
  handleSubmit: (stripe: any, elements: any) => Promise<void>; // Define a prop for the handleSubmit function
  isChecked: boolean; // Add a prop for isChecked
  setChecked: (isChecked: boolean) => void; // Add a prop for setChecked
}

const CheckoutForm: FC<Props> = ({
  setStripe,
  setElements,
  setError,
  error,
  formRef,
  handleSubmit,
  isChecked,
  setChecked,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setStripe(stripe);
    setElements(elements);
  }, [stripe, elements, setStripe, setElements]);

  const handleChange = (e: any) => {
    if (e.error) {
      setError(e.error.message);
    } else {
      setError(null);
    }
  };

  const { selectedProperty } = useSelector((state: any) => state?.search);
  const { t } = useTranslation();

  return (
    <form ref={formRef} className="bookingForm">
      <div className="colsHolder">
        <div className="chCol chCol12">
          <div className="formGroup">
            <label>
              {t("name_on_card")}
              <input
                type="text"
                id="inputCardholderName"
                className="formControl"
                placeholder="Name on card"
              />
            </label>
          </div>
        </div>
        <div className="chCol chCol12">
          <div className="formGroup">
            <label>
              {t("card_number")}
              <CardNumberElement
                className="formControl"
                onChange={handleChange}
              />
              {/* {error && <p className="error-message">{error}</p>} */}
            </label>
          </div>
        </div>
        <div className="chCol chCol6">
          <div className="formGroup">
            <label>
              {t("expiry_date")}
              <CardExpiryElement
                className="formControl"
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
        <div className="chCol chCol6">
          <div className="formGroup">
            <label>
              {t("cvc")}
              <CardCvcElement className="formControl" onChange={handleChange} />
            </label>
          </div>
        </div>
      </div>
      <div className="termConditionWrapper">
        <div>
          {getNameFromLocale(
            selectedProperty.cancellation_policy,
            i18n.resolvedLanguage
          ) && (
            <div
              style={{ marginBottom: "20px" }}
              dangerouslySetInnerHTML={{
                __html: getNameFromLocale(
                  selectedProperty.cancellation_policy,
                  i18n.resolvedLanguage
                ),
              }}
            ></div>
          )}
        </div>

        <div className="checkBoxWrap">
          <input
            type="checkbox"
            id="terms_and_conditions"
            checked={isChecked}
          />

          <label
            onClick={() => setChecked(!isChecked)}
            className="formConfirmText"
          >


            <Trans
              i18nKey="agreement"
              components={{
                1: <a href="#">{t("terms")}</a>,
                2: <a href="#">{t("privacy")}</a>,
              }}
            />
          </label>
        </div>
      </div>
    </form>
  );
};

export default CheckoutForm;
