import React, { Suspense, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
// pages for this product
import HomePage from "./views/HomePage/HomePage.js";
import { Helmet } from "react-helmet";
// null   Anyone Can go inside
// true   only logged in user can go inside
// false  logged in user can't go inside
import "../assets/style.css";
import "../services/i18n";

import FormPage from "./views/FormPage/FormPage.js";
import NotFoundPage from "./views/NotFoundPage/index.js";

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Rentalls</title>
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link
          rel='preconnect'
          href='https://fonts.gstatic.com'
          crossOrigin='anonymous'
        />

        <link
          href='https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
          rel='stylesheet'
        />
        <link
          rel='stylesheet'
          href='//unpkg.com/leaflet@1.7.1/dist/leaflet.css'
          integrity='sha384-qZjXG1vOZNTQdA3pf9iMOhjOPQDojtkQW1zVS9gdCWEH2fh+U2xpdQ5M8MZrSs0S'
          crossOrigin='use-credentials'
        />
        <link
          rel='stylesheet'
          href='https://code.jquery.com/ui/1.13.2/themes/base/jquery-ui.css'
        />
        <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css'
        />
      </Helmet>
      <div id='pageWrapper'>
        <Switch>
          <Route exact path='/:tenant/' component={HomePage} />
          <Route exact path='/:tenant/book/:id' component={FormPage} />
          <Route path='*' component={NotFoundPage} />
        </Switch>
      </div>

      <Helmet>
        {/* Script tags to be added to the footer */}
        {/* <script async defer src="https://code.jquery.com/jquery-3.6.0.js" />
        <script async defer src="https://code.jquery.com/ui/1.13.2/jquery-ui.js" /> */}
        <script async defer src='https://kit.fontawesome.com/391f644c42.js' />
        {/* <script
          src="https://unpkg.com/leaflet@1.9.3/dist/leaflet.js"
          integrity="sha256-WBkoXOwTeyKclOHuWtc+i2uENFpDZ9YPdf5Hf+D7ewM="
          crossorigin
        ></script> */}
      </Helmet>
    </Suspense>
  );
}

export default App;
